.label-bold {
  font-weight: bold;
}

.user-list-item h5 {
  font-size: 1em;
}
.user-list-item h6 {
  font-size: 0.85em;
}

.control-buttons {
  margin-right: 10px;
}
label {
  font-weight: bold;
}
.invalid {
  color: red;
}

.button-spacer {
  margin-right: 10px;
}
.information-panel {
  margin-top: 15px;
}

.candidate-panel {
}
.candidate-item {
  cursor: pointer;
}
.list-group-item-text {
  margin-bottom: 0;
}
.bottom-spacer {
  margin-bottom: 15px;
}
.criteria-label {
  font-weight: normal;
}
.select-text {
  margin-bottom: 0;
}
.user-name {
}
.user-email {
  font-size: 0.8rem;
  font-style: italic;
}
input#admin {
  margin-left: 0;
  margin-top: 12px;
}
.administrator-message {
  margin: 8px 0 0 25px;
}
.admin-italic {
  font-style: italic;
  font-size: 0.85em;
  padding-left: 10px;
}
.invalid {
  color: red;
  border-color: red;
}
.dupe {
  color: red;
}
.new-student-added {
  margin-top: 10px;
}
