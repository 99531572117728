.label-bold {
  font-weight: bold;
}

.user-list-item h5 {
  font-size: 1em;
}
.user-list-item h6 {
  font-size: 0.85em;
}

.control-buttons {
  margin-right: 10px;
}
label {
  font-weight: bold;
}
.invalid {
  color: red;
}

.button-spacer {
  margin-right: 10px;
}
.information-panel {
  margin-top: 15px;
}
.bad-import-data {
  background-color: rgb(244, 204, 205);
  padding: 5px;
  margin: -5px;
  border-radius: 5px;
}
.import-data-list-header {
  font-weight: bold;
}

.candidate-panel {
}
.candidate-item {
  cursor: pointer;
}
.list-group-item-text {
  margin-bottom: 0;
}
.bottom-spacer {
  margin-bottom: 15px;
}
.criteria-label {
  font-weight: normal;
}

.import-warning {
  color: red;
  font-weight: bold;
}
.import-fields {
  font-weight: bold;
}
#skipFirst {
  margin-left: 8px;
  margin-top: 6px;
}
.import-failed {
  color: red;
}
.bold-label {
  font-weight: bold;
}
.card-header h5 {
  margin-bottom: 0;
}

hr {
  background-color: black;
  border-width: 4px;
}
.ps-field {
  font-style: italic;
  color: #666;
  font-size: 0.85rem;
}
