.summary-heading {
  font-weight: bold;
  padding-top: 15px;
}
.indent {
  margin-left: 10px;
}
td {
  border-bottom: 1px solid #ccc;
}
.total {
  font-weight: bold;
}
