/*.login-label-bold {font-weight: bold;}*/
/*.login-button {cursor: pointer; width: 175px;}*/
/*.login-label {margin: 12px 20px 0 15px; font-weight: bold;}*/

/*.firebaseui-idp-text-short {display: none;}*/
/*.firebaseui-idp-text {font-size: 1.35rem;}*/
/*.firebaseui-list-item {padding: 10px;}*/
/*.firebaseui-idp-icon {height: 40px; margin-right: 20px;}*/
/*.firebaseui-idp-button {width: 300px; height: 70px;}*/
/*.firebaseui-idp-list {list-style-type: none; padding: 0; margin: 0; text-align: center;}*/
/*.firebaseui-card-footer {display: none;}*/

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0288D1 radial-gradient(circle, #039BE5, #01579b);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
  font-family: sans-serif;
}
.signedIn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.logo {
  font-family: 'Amaranth', sans-serif;
  font-size: 200%;
}
.logoIcon {
  top: 4px;
  font-size: 32px;
  margin-right: -2px;
  position: relative;
}
.caption {
  margin: 20px 0 40px 0;
  font-family: 'Amaranth', sans-serif;
  font-size: 18px;
  opacity: 0.8;
}
.skip {
  font-weight:lighter;
  color:white;
  opacity: 0.7;
  width: 100%;
  display: block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.skip:HOVER {
  text-decoration: underline;
}
.firebaseUi {
  min-width: 250px;
}
.button {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  margin-top: 10px;
}
.firebaseui-card-footer {display: none;}
.firebaseui-idp-password {background-color: rgb(23,127,251) !important; color: white !important;}
.firebaseui-idp-text-long {font-size: 1.1rem !important;}
.firebaseui-idp-button {width: 300px !important; height: 45px !important;}
.mdl-button--colored {background: #3f51b5 !important;}

