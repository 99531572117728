.audit-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.70rem;
}
.audit-table tbody tr td,
.audit-table thead tr th {
  border: 1px solid #bbb;
  height: 40px;
}
.audit-table tbody tr td span,
.audit-table thead tr th span {
  padding-left: 5px;
}
.pending-button {
  height: 25px;
  padding: 0 10px;
  margin-left: 10px;
}
