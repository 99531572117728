.roster-table {
  width: 100%;
  border-collapse: collapse;
}
.roster-table tbody tr td,
.roster-table thead tr th {
  border-bottom: 1px solid #888;
  height: 40px;
}
.roster-control-buttons {
  margin-top: 11px;
}
.transaction-notes {font-size: 0.80rem; width: 150px}
.roster-table tbody tr td {vertical-align: top; padding-top: 8px; padding-bottom: 8px;}
