/*.label-bold {font-weight: bold;}*/

/*.user-list-item h5 {font-size: 1em;}*/
/*.user-list-item h6 {font-size: 0.85em;}*/

/*.control-buttons {margin-right: 10px;}*/
/*label {font-weight: bold;}*/
/*.invalid {color: red;}*/

/*.button-spacer {margin-right: 10px;}*/
/*.information-panel {margin-top: 15px;}*/

.candidate-panel {
}
.candidate-item {
  cursor: pointer;
}
.list-group-item-text {
  margin-bottom: 0;
}
.bottom-spacer {
  margin-bottom: 15px;
}
.criteria-label {
  font-weight: normal;
}

.btn {
  margin-right: 10px;
}
.event-separator {
  margin-bottom: 15px;
}
.label-center {
  margin-top: 6px;
}
.app-min-height {
  min-height: 500px;
}
.resend-button {
  height: 23px;
  padding: 0 10px;
}
.transaction-history {
  margin-top: 20px;
  font-size: 0.8rem;
}
.transaction-history th,
.transaction-history td {
  text-align: center;
}
.transaction-history button {
  margin: 0;
}
.transaction-history-header {
  font-size: 1rem;
  background-color: rgb(247, 247, 247);
}

.student-list-container {
  max-height: 500px;
  overflow-y: scroll;
}
.student-list-filter {
  width: 200px;
}
.student-list-filter-container {
  margin-bottom: 10px;
}
.card-header {
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
}
.list-item {
  cursor: pointer;
  font-size: 0.9rem;
}
.student-list-item {
  display: inline-block;
}
.student-list-item-badge {
  text-align: right;
}
.student-on-lop {
  background-color: red;
  color: white;
}
.lop-detail {
  font-size: 0.8rem;
  text-align: center;
  color: red;
}
.ticket {
  min-height: 400px;
}
.ticket-body {
  vertical-align: middle;
}
.lop-message-separator {
  margin-top: 25px;
}
.ticket-display-label {
  margin-top: 10px;
}
.ticket-line-spacing {
  height: 10px;
}
.ticket-line-spacing-riser {
  margin-top: -25px;
}
.ticket-price-radios {
  margin-top: 8px;
  margin-left: 10px;
}
.non-bold {
  font-weight: normal;
}
.lop-status {
  font-style: italic;
}
.highlight-background {
  background-color: #eee;
  border-radius: 10px;
  margin: -5px;
  padding: 10px;
}
.button-separator {
  margin-top: 25px;
}
.ticketed {
  background-color: rgb(182, 214, 169);
}
.student-info {
  font-size: 0.85rem;
  font-weight: normal;
}
.refund-notice {
  margin-top: -2px;
  line-height: 1.25;
  font-style: italic;
}
