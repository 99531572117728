.label-bold {
  font-weight: bold;
}

.user-list-item h5 {
  font-size: 1em;
}
.user-list-item h6 {
  font-size: 0.85em;
}

.control-buttons {
  margin-right: 10px;
}
label {
  font-weight: bold;
}
.invalid {
  color: red;
}
.error {
  color: red;
}

.button-spacer {
  margin-right: 10px;
}
.information-panel {
  margin-top: 15px;
}

.candidate-panel {
}
.candidate-item {
  cursor: pointer;
}
.list-group-item-text {
  margin-bottom: 0;
}
.bottom-spacer {
  margin-bottom: 15px;
}
.criteria-label {
  font-weight: normal;
}
.event-status {
  margin-top: 25px;
}
